<template>
  <div class="chat-container">
    <div class="messages" ref="messagesContainer">
      <div v-for="(message, index) in messages" :key="index" class="message">
        <strong>{{ message.sender }}:</strong>
        <br />
        <span v-html="message.text"></span>
        <div v-if="message.files && message.files.length" class="file-list">
          <div
            v-for="(file, fIndex) in message.files"
            :key="fIndex"
            class="file-item"
          >
            📎 {{ file.name }}
          </div>
        </div>
      </div>
    </div>
    <div class="input-container">
      <input
        v-model="newMessage"
        @keyup.enter="sendMessage"
        placeholder="Type your message"
        :disabled="isLoading"
      />
      <button @click="sendMessage" :disabled="isLoading">
        <span v-if="isLoading" class="loader"></span>
        <span v-else>Send</span>
      </button>
      <!-- <button @click="triggerFileInput" class="file-button">+</button> -->
      <input
        type="file"
        ref="fileInput"
        @change="handleFileChange"
        multiple
        style="display: none"
      />
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      messages: [],
      newMessage: '',
      username: 'You',
      selectedFiles: [],
      isLoading: false,
    };
  },
  watch: {
    messages() {
      this.$nextTick(() => {
        this.scrollToLatestMessage();
      });
    },
  },
  computed: {
    assistantId() {
      return this.$route.params.assistant_id;
    },
  },
  methods: {
    async sendMessage() {
      if (this.newMessage.trim() !== '' || this.selectedFiles.length > 0) {
        const userMessage = {
          sender: this.username,
          text: this.newMessage,
          files: this.selectedFiles.map((file) => ({ name: file.name })),
        };
        this.messages.push(userMessage);

        this.newMessage = '';
        this.selectedFiles = [];

        this.isLoading = true;
        try {
          const formData = new FormData();
          formData.append('prompt', userMessage.text);
          this.selectedFiles.forEach((file) => {
            formData.append('file', file);
          });

          const response = await axios.post(
            `/assistant-api/ask/${this.assistantId}`,
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            },
          );

          console.log(response.data);

          response.data.forEach((item) => {
            item.content.forEach((content) => {
              if (content.type === 'text') {
                this.messages.push({
                  sender: 'Assistant',
                  text: content.text.value,
                  files: [],
                });
              }
            });
          });
        } catch (error) {
          console.error('Error sending message:', error);
          this.messages.push({
            sender: 'System',
            text: 'Error sending message. Please try again.',
            files: [],
          });
        } finally {
          this.isLoading = false;
        }
      }
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      this.selectedFiles = Array.from(event.target.files);
    },
    scrollToLatestMessage() {
      const container = this.$refs.messagesContainer;
      if (container) {
        container.scrollTop = container.scrollHeight;
      }
    },
  },
};
</script>

<style scoped>
.chat-container {
  display: flex;
  flex-direction: column;
  width: 90%; /* Make the chat container a little less wide */
  height: calc(
    100vh - 110px
  ); /* Adjust height to fit below the navbar, adjust the 60px according to your navbar height */
  margin: 20px auto; /* Add margin to center and add gap from navbar */
  padding: 10px; /* Add padding for better aesthetics */
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.messages {
  flex: 1;
  overflow-y: auto;
  margin-bottom: 10px;
  border: 1px solid #e0e0e0;
  padding: 10px;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.message {
  margin-bottom: 10px;
  word-wrap: break-word;
}

.file-list {
  margin-top: 5px;
  padding-left: 10px;
}

.file-item {
  font-size: 0.9em;
  color: #555;
}

.input-container {
  display: flex;
  width: 100%;
  box-sizing: border-box; /* Ensure padding and borders are included in width */
}

.input-container > input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px 0 0 5px; /* Round the left corners */
  box-sizing: border-box; /* Ensure padding and borders are included in width */
  height: 40px; /* Ensure height is consistent with button */
}

.input-container > button {
  padding: 10px 20px;
  border: none;
  background-color: #42b983;
  color: white;
  border-radius: 0 5px 5px 0; /* Round the right corners */
  cursor: pointer;
  height: 40px; /* Ensure height is consistent with input */
}

.input-container > button:hover {
  background-color: #39a274;
}

.file-button {
  background-color: #2c3e50;
}

.file-button:hover {
  background-color: #1a2a37;
}

.loader {
  border: 2px solid #f3f3f3; /* Light grey */
  border-top: 2px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 12px;
  height: 12px;
  animation: spin 1s linear infinite;
  display: inline-block;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 600px) {
  .chat-container {
    padding: 5px;
    width: 100%;
  }

  .input-container button {
    padding: 5px 10px;
  }

  .input-container > input {
    padding: 5px;
  }
}
</style>
